














import { defineComponent } from '@nuxtjs/composition-api';
import { useConfigStore } from '~/stores/config';

export default defineComponent({
  name: 'FacebookChatButtonMobile',
  props: {
    page_name: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const configStore = useConfigStore();
    const facebook_page_id = configStore.$state.storeConfig.facebook_page_id;

    return {
      facebook_page_id,
    };
  }
});












































import LazyHydrate from "vue-lazy-hydration";
import {
  useRoute,
  defineComponent,
  ref,
  onMounted,
  useFetch,
  useContext,
} from "@nuxtjs/composition-api";
import { useUiState } from "~/composables";
import AppHeader from "~/components/AppHeader.vue";
import IconSprite from "~/components/General/IconSprite.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import TopBar from "~/components/TopBar/TopBar.vue";
import FacebookChatButtonMobile from "~/components/FacebookChatButtonMobile.vue";
import { useConfig } from "~/composables";
import type { CmsBlock } from "~/modules/GraphQL/types";
import ScrollToTopBtn from "~/components/ScrollToTopBtn.vue";
import { loadBlocksCommand } from "~/composables/useContent/commands/loadBlocksCommand";
// import "~/assets/fontawesome/css/all.min.css";

export default defineComponent({
  name: "DefaultLayout",

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    IconSprite,
    TopBar,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ "~/components/AppFooter.vue"),
    CartSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/checkout/components/CartSidebar.vue"
      ),
    WishlistSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/wishlist/components/WishlistSidebar.vue"
      ),
    LoginModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginModal.vue"
      ),
    PopupModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/customer/components/PopupModal/PopupModal.vue"
      ),
    LoginCheckoutModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginCheckoutModal.vue"
      ),
    UseCouponModal: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Coupon/UseCouponModal.vue"
      ),
    SoldoutCouponModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/checkout/pages/Cart/SoldoutCouponModal.vue"
      ),
    SummaryPaymentModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/customer/components/CheckoutModal/SummaryPaymentModal.vue"
      ),
    Notification: () =>
      import(/* webpackPrefetch: true */ "~/components/Notification.vue"),
    FacebookChatButtonMobile,
    ScrollToTopBtn,
  },

  setup(_, { emit }) {
    const route = useRoute();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      isPopupModalOpen,
      togglePopupModal,
      isLoginCheckoutModalOpen,
      toggleLoginCheckoutModal,
      isSummaryPaymentModalOpen,
      toggleSummaryPaymentModal,
      isUseCouponModalOpen,
      toggleUseCouponModal,
      isSoldoutCouponModalOpen,
      toggleSoldoutCouponModal
    } = useUiState();

    const { config } = useConfig();

    const blocks = ref<CmsBlock[]>([]);
    const popupContent = ref("");
    const { app } = useContext();
    const context = app.$vsf;

    const isMobile = ref(false);
    const popup = ref();

    // const { fetch, fetchState } = useFetch(async () => {
    //   blocks.value = await loadBlocksCommand.execute(context, { identifiers: [popupContent.value] });
    // });
    // fetch();

    onMounted(async () => {
      if (window.innerWidth < 1024) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }

      popup.value = config.value;
      popupContent.value = popup.value.popup_content;
      blocks.value = await loadBlocksCommand.execute(context, {
        identifiers: [popupContent.value],
      });
      const getCookie = async (cookieName: string) => {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(";");

        for (let i = 0; i < cookieArray.length; i++) {
          let cookie = cookieArray[i];

          while (cookie.charAt(0) === " ") {
            cookie = cookie.substring(1);
          }
          if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
          }
        }
        return null; // Return null if the cookie is not found
      };
      if (popup.value.popup_enable == "1" && !(await getCookie("closeModal"))) {
        togglePopupModal(true);
      } else {
        togglePopupModal(false);
      }
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      isPopupModalOpen,
      togglePopupModal,
      isLoginCheckoutModalOpen,
      toggleLoginCheckoutModal,
      isSummaryPaymentModalOpen,
      toggleSummaryPaymentModal,
      isUseCouponModalOpen,
      toggleUseCouponModal,
      isSoldoutCouponModalOpen,
      toggleSoldoutCouponModal,
      route,
      blocks,
      isMobile,
    };
  },
  head: {
    // link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});

import Vue from 'vue';
import VueFbCustomerChat from 'vue-fb-customer-chat';
import { Plugin } from '@nuxt/types';
import { useConfigStore } from '~/stores/config';

const plugin : Plugin = (context, inject) => {
  const configStore = useConfigStore();
  const facebook_enable = configStore.$state.storeConfig.facebook_enable;
  const facebook_page_id = configStore.$state.storeConfig.facebook_page_id;
  const facebook_color_option = configStore.$state.storeConfig.facebook_color_option;
  if (facebook_enable) {
    Vue.use(VueFbCustomerChat, {
      page_id: facebook_page_id, //  change 'null' to your Facebook Page ID,
      theme_color: facebook_color_option, // theme color in HEX
      locale: 'th_TH', // default 'en_US'
    })
  }
};

export default plugin;
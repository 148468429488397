







import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import IconUp from '~/components/General/IconUp.vue';

export default defineComponent({
  name: 'ScrollToTopBtn',
  components: {
    IconUp,
  },
  setup() {
    const fadeIn = ref(false);
    const fadeOut = ref(true);
    const toTop = (event) => {
      event.preventDefault();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    onMounted(async () => {
      if (process.client) { 
        window.addEventListener("scroll", function (e) {
          if (window.pageYOffset >= 300) {
            fadeIn.value = true;
            fadeOut.value = false;
          } else {
            fadeIn.value = false;
            fadeOut.value = true;
          }
        });
      }
    });
    return {
      fadeIn,
      fadeOut,
      toTop,
    };
  }
});

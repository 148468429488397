/* eslint-disable  */

export default {
  "About us": "เกี่ยวกับเรา",
  "Add new address": "เพิ่มที่อยู่ใหม่",
  "Add to compare": "เพิ่มเพื่อเปรียบเทียบ",
  "Add to Wishlist": "เพิ่มในรายการที่ต้องการ",
  "Additional Information": "ข้อมูลเพิ่มเติม",
  "All Orders": "คำสั่งซื้อทั้งหมด",
  "Allow order notifications": "อนุญาตการแจ้งเตือนคำสั่งซื้อ",
  Apply: "นำมาใช้",
  "Applied Coupon": "ใช้คูปอง",
  "Attention!": "ความสนใจ!",
  "Back to home": "กลับไปที่หน้าหลัก",
  "Back to homepage": "กลับไปที่หน้าหลัก",
  Billing: "การเรียกเก็บเงิน",
  "Billing address": "ที่อยู่ในใบเสร็จ",
  Brand: "ยี่ห้อ",
  Cancel: "ยกเลิก",
  Cart: "รถเข็น",
  Categories: "หมวดหมู่",
  Change: "เปลี่ยน",
  "Change password your account": "หากคุณต้องการเปลี่ยนรหัสผ่านเพื่อเข้าถึงบัญชีของคุณ ให้ป้อนข้อมูลต่อไปนี้",
  "Clear all": "ลบทั้งหมด",
  Color: "สี",
  "Commercial information": "และตกลงที่จะรับข้อมูลทางการค้าส่วนบุคคลจากชื่อแบรนด์ทางอีเมล",
  "Contact details updated": "อัปเดตที่อยู่และรายละเอียดการติดต่อของคุณ",
  "Contact us": "ติดต่อเรา",
  "Continue to billing": "ดำเนินการต่อ",
  "Continue to payment": "ชำระเงินต่อ",
  "Continue to shipping": "ส่งสินค้าต่อค่ะ",
  "Cookies Policy": "นโยบายคุกกี้",
  "Customer Reviews": "ความคิดเห็นของลูกค้า",
  "Customer service": "บริการลูกค้า",
  Date: "วันที่",
  "Default Billing Address": "ที่อยู่สำหรับการเรียกเก็บเงินเริ่มต้น",
  "Default Shipping Address": "ที่อยู่จัดส่งเริ่มต้น",
  Delete: "ลบ",
  Departments: "หน่วยงาน",
  Description: "คำอธิบาย",
  "Details and status orders": "ตรวจสอบรายละเอียดและสถานะการสั่งซื้อของคุณในร้านค้าออนไลน์ คุณยังสามารถยกเลิกคำสั่งซื้อของคุณหรือขอคืนสินค้าได้",
  Discount: "ส่วนลด",
  "Discount coupon": "คูปองส่วนลด",
  "Period": "ระยะเวลา",
  "Can be use from": "ใช้ได้ตั้งแต่",
  "Note: We reserve the right to change conditions without prior notice.": "หมายเหตุ : ขอสงวนสิทธิ์การเปลี่ยนแปลงเงื่อนไขโดยไม่ต้องให้ทราบล่วงหน้า",
  "Promotion conditions": "เงื่อนไขรายการส่งเสริมการขาย",
  "kepted coupon": "เก็บคูปองแล้ว",
  "received coupon": "ได้รับสิทธิ์แล้ว",
  "usedfull coupon": "สิทธิ์เต็มแล้ว",
  "soldout coupon": "คูปองหมด",
  "Close": "ปิด",
  "Limited quantity discount": "ส่วนลดจำนวนจำกัด",
  "Rights/Order/Email": "สิทธิ์/การสั่งซื้อ/อีเมล์",
  Done: "เสร็จแล้ว",
  "Download all": "ดาวน์โหลดทั้งหมด",
  Edit: "แก้ไข",
  "Email address": "ที่อยู่อีเมล",
  Empty: "ดูเหมือนว่าคุณยังไม่ได้เพิ่มรายการใดๆ ลงในกระเป๋าเลย เริ่มช้อปปิ้งเพื่อเติมเต็ม",
  "Empty bag": "ถุงเปล่า",
  "Enjoy your free account": "เพลิดเพลินไปกับสิทธิพิเศษเหล่านี้ด้วยบัญชีฟรีของคุณ!",
  "Enter promo code": "ใส่รหัสคูปอง",
  "Coupon Code": "โค้ดโปรโมชั่น",
  "Enter the coupon code according to the promotion you want by": "กรอกรหัสคูปองตามโปรโมชั่นที่คุณต้องการใช้ โดยเป็นไปตาม",
  "terms and conditions": "เงื่อนไขการใช้งาน",
  "Cancel Coupon": "ยกเลิก",
  "Item Price": "ราคาต่อชิ้น",
  "Item Row Total": "ราคารวม",
  "Save Money": "ประหยัดไป",
  "Secret discount deals": "ดีลลับส่วนลด",
  "See All Coupon": "ดูคูปองทั้งหมด",
  "There are a limited number. Don't wait. Hurry up to collect and use it.": "มีจำนวนจำกัด อย่ารอช้า รีบมาเก็บและใช้กันเร็ว",
  "Collect coupon": "เก็บคูปอง",
  "Collected coupon": "เก็บคูปองแล้ว",
  "Expire": "หมดอายุ",
  "Additional conditions": "เงื่อนไขเพิ่มเติม",
  "Special deals are included here.": "รวมดีลสุดพิเศษไว้ที่นี้แล้ว",
  "Search coupons": "ค้นหาคูปอง",
  Feedback: "ความคิดเห็นของคุณมีความสำคัญต่อเรา แจ้งให้เราทราบว่าเราสามารถปรับปรุงอะไรได้บ้าง",
  "Feel free to edit": "อย่าลังเลที่จะแก้ไขรายละเอียดของคุณด้านล่างเพื่อให้บัญชีของคุณเป็นปัจจุบันอยู่เสมอ",
  Filters: "ตัวกรอง",
  "Forgot Password": "หากคุณจำรหัสผ่านไม่ได้ คุณสามารถรีเซ็ตรหัสผ่านได้",
  "Forgot Password Modal Email": "อีเมลที่คุณใช้ลงชื่อเข้าใช้:",
  forgotPasswordConfirmation: "ขอบคุณ! หากมีบัญชีที่ลงทะเบียนกับอีเมล {0} คุณจะพบข้อความพร้อมลิงก์รีเซ็ตรหัสผ่านในกล่องจดหมายของคุณ",
  "Go back": "ย้อนกลับ",
  "Go back shopping": "กลับไปช๊อปปิ้ง",
  "Go back to shop": "กลับไปที่ร้านค้า",
  "Go to checkout": "ไปเช็คเอาท์",
  "Go to cart": "ไปที่รถเข็น",
  Guarantee: "รับประกัน",
  Help: "ช่วย",
  hide: "ซ่อน",
  "I agree to": "ฉันตกลง",
  "I confirm subscription": "ฉันยืนยันการสมัครสมาชิก",
  "Info after order":
    "คุณสามารถเข้าสู่ระบบบัญชีของคุณโดยใช้อีเมลและรหัสผ่านที่กำหนดไว้ก่อนหน้านี้ ในบัญชีของคุณ คุณสามารถแก้ไขข้อมูลโปรไฟล์ ตรวจสอบประวัติการทำธุรกรรม แก้ไขการสมัครรับจดหมายข่าว",
  Instruction1: "ดูแลฉัน",
  Instruction2: "เพียงแค่ที่นี่สำหรับคำแนะนำในการดูแล?",
  Instruction3: "ใช่ เราก็คิดอย่างนั้น",
  "It was not possible to request a new password, please check the entered email address.":
    "ไม่สามารถขอรหัสผ่านใหม่ได้ โปรดตรวจสอบที่อยู่อีเมลที่ป้อน",
  Item: "รายการ",
  Items: "รายการ",
  Kidswear: "Kidswear",
  "Last Name": "Last Name",
  "Let’s start now – we’ll help you": "Let’s start now – we’ll help you.",
  "Log into your account": "Log into your account",
  Login: "Login",
  "login in to your account": "login in to your account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.":
    "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Make an order": "Make an order",
  "Manage addresses":
    'จัดการที่อยู่ทั้งหมดที่คุณต้องการ (ที่ทำงาน ที่อยู่บ้าน...) ด้วยวิธีนี้ คุณจะไม่ต้องป้อนที่อยู่ด้วยตนเองในคำสั่งซื้อแต่ละรายการ',
  "Manage billing addresses":
    'Manage all the billing addresses you want (work place, home address...) This way you won"t have to enter the billing address manually with each order.',
  "Manage shipping addresses":
    'Manage all the shipping addresses you want (work place, home address...) This way you won"t have to enter the shipping address manually with each order.',
  "Match it with": "Match it with",
  "Men fashion": "Men fashion",
  Menu: "เมนู",
  "My billing and shipping address are the same": "ที่อยู่สำหรับการเรียกเก็บเงินและการจัดส่งเหมือนกัน",
  "My Cart": "รถเข็นของฉัน",
  "No account": "Don't have an account yet?",
  or: "หรือ",
  "or fill the details below": "or fill the details below",
  "Order ID": "รหัสคำสั่งซื้อ",
  "Order information": "ข้อมูลการสั่งซื้อ",
  "Order No.": "เลขที่ใบสั่งซื้อ",
  "Order summary": "สรุปการสั่งซื้อ",
  "Other products you might like": "Other products you might like",
  "Out of stock": "สินค้าหมด",
  Password: "Password",
  "Password Changed": "Password successfuly changed. You can now go back to homepage and sign in.",
  "Pay for order": "Pay for order",
  Payment: "การชำระเงิน",
  "Make Payment": "ชำระเงิน",
  "Payment & Delivery": "Payment & Delivery",
  "Payment Method": "วิธีการชำระเงิน",
  "Payment Methods": "วิธีการชำระเงิน",
  "Personal details": "ข้อมูลส่วนตัว",
  "Please type your current password to change your email address.": "Please type your current password to change your email address.",
  Price: "Price",
  Privacy: "ความเป็นส่วนตัว",
  "Privacy Policy": "นโยบายความเป็นส่วนตัว",
  Product: "สินค้า",
  "Product suggestions": "Product suggestions",
  Products: "สินค้า",
  "Products found": "Products found",
  "Purchase terms": "Purchase terms",
  "Quality in the details": "Quality in the details",
  Quantity: "จำนวน",
  "Read all reviews": "Read all reviews",
  "Read and understand": "ฉันได้อ่านและเข้าใจแล้ว",
  "Read reviews": "Read reviews",
  Register: "Register",
  "Register today": "Register today",
  Remove: "Remove",
  "Remove Address": "Remove Address",
  "Remove from Wishlist": "ลบจากรายการที่ต้องการ",
  "Repeat Password": "ยืนยันรหัสผ่าน",
  "Reset Password": "Reset Password",
  "Review my order": "Review my order",
  "Same as shipping address": "Same as shipping address",
  "Save changes": "Save changes",
  "Save for later": "Save for later",
  "Save Password": "Save Password",
  Search: "Search",
  "Search for items": "Search for items",
  "Search results": "Search results",
  "Sections that interest you": "Sections that interest you",
  "See all results": "See all results",
  "See more": "See more",
  "Select payment method": "Select payment method",
  "Select shipping method": "Select shipping method",
  "Send my feedback": "Send my feedback",
  "Set up newsletter":
    "จัดทำจดหมายข่าวของคุณแล้วเราจะส่งข้อมูลเกี่ยวกับผลิตภัณฑ์และเทรนด์ใหม่จากส่วนที่คุณเลือกทุกสัปดาห์",
  "Share your look": "Share your look",
  Shipping: "การจัดส่ง",
  "Shipping address": "Shipping address",
  "Shipping details": "Shipping details",
  "Shipping method": "Shipping method",
  Show: "แสดง",
  "show more": "show more",
  "Show on page": "Show on page",
  "Sign in": "ลงชื่อเข้าใช้",
  "Size guide": "Size guide",
  "Sign Up for Newsletter": "Sign Up for Newsletter",
  "Sort by": "เรียงตาม",
  "Sort: Default": "ตำแหน่ง",
  "Sort: Name A-Z": "ชื่อสินค้า A-Z",
  "Sort: Name Z-A": "ชื่อสินค้า Z-A",
  "Sort: Price from high to low": "ราคาจากมากไปน้อย",
  "Sort: Price from low to high": "ราคาจากน้อยไปมาก",
  "Sort: Relevance": "ความเกี่ยวข้อง",
  "Start shopping": "Start shopping",
  Status: "Status",
  Subscribe: "Subscribe",
  "Subscribe to newsletter": "Subscribe to newsletter",
  subscribeToNewsletterModalContent:
    "After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.",
  Subtotal: "ยอดรวม",
  "Subtotal price": "Subtotal price",
  "Successful placed order":
    "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.",
  "Terms and conditions": "นโยบายความเป็นส่วนตัว",
  "terms-and-conditions": "นโยบายความเป็นส่วนตัว",
  "Thank you": "Thank you",
  "Thank You Inbox": "If the message is not arriving in your inbox, try another email address you might’ve used to register.",
  Total: "ยอดรวมทั้งหมด",
  "Order Total": "ยอดรวมการสั่งซื้อ",
  "Order Totals": "ยอดรวมสุทธิ",
  "Total items": "รายการทั้งหมด",
  "Total price": "ยอดรวมสุทธิ",
  "Shipping rate": "ค่าจัดส่ง",
  "Update password": "Update password",
  "Update personal data": "อัพเดทข้อมูลส่วนตัว",
  "Use your personal data":
    "ที่ชื่อแบรนด์ เราให้ความสำคัญอย่างยิ่งกับปัญหาความเป็นส่วนตัวและมุ่งมั่นที่จะปกป้องข้อมูลส่วนบุคคลของผู้ใช้ของเรา เรียนรู้เพิ่มเติมเกี่ยวกับวิธีที่เราดูแลและใช้ข้อมูลส่วนบุคคลของคุณใน",
  "User Account": "บัญชีผู้ใช้",
  View: "View",
  "View details": "View details",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Who we are",
  Wishlist: "Wishlist",
  "Women fashion": "Women fashion",
  "You added {product} to your shopping cart.": "You added {product} to your shopping cart.",
  "You are not authorized, please log in": "คุณไม่ได้รับอนุญาต กรุณาเข้าสู่ระบบ",
  "You can unsubscribe at any time": "You can unsubscribe at any time",
  "You currently have no orders": "You currently have no orders",
  "You haven’t searched for items yet": "You haven’t searched for items yet.",
  "Your bag is empty": "Your bag is empty",
  "Your current email address is": "Your current email address is",
  "Your email": "อีเมล์ของคุณ",
  "The email field must be a valid email": "The email field must be a valid email",
  "You have submitted no reviews": "คุณยังไม่ได้ส่งคำวิจารณ์",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
  "A customer with the same email address already exists in an associated website.":
    "A customer with the same email address already exists in an associated website.",
  "Invalid email": "Invalid email",
  "SUMMER COLLECTION {year}": "SUMMER COLLECTION {year}",
  "Colorful summer dresses are already in store": "Colorful summer dresses are already in store",
  "Learn more": "Learn more",
  Dresses: "Dresses",
  "Cocktail & Party": "Cocktail & Party",
  "Linen Dresses": "Linen Dresses",
  "T-Shirts": "T-Shirts",
  "The office life": "The office life",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.":
    "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  "Shop now": "Shop now",
  "The Office Life": "The Office Life",
  "Summer Sandals": "Summer Sandals",
  "Eco Sandals": "Eco Sandals",
  "Subscribe to Newsletters": "Subscribe to Newsletters",
  "Be aware of upcoming sales and events. Receive gifts and special offers!":
    "Be aware of upcoming sales and events. Receive gifts and special offers",
  "Fashion to take away": "Fashion to take away",
  "Download our application to your mobile": "Download our application to your mobile",
  "Share Your Look": "Share Your Look",
  "My Account": "บัญชีของฉัน",
  "My profile": "ประวัติของฉัน",
  "Personal Details": "ข้อมูลส่วนตัว",
  "Addresses details": "รายละเอียดที่อยู่",
  "My newsletter": "จดหมายข่าวของฉัน",
  "Log out": "ออกจากระบบ",
  "My reviews": "ความคิดเห็นของฉัน",
  "Order history": "ประวัติการสั่งซื้อ",
  "Order details": "รายละเอียดคำสั่งซื้อ",
  "My wishlist": "สิ่งที่อยากได้ของฉัน",
  "Password change": "เปลี่ยนรหัสผ่าน",
  "Personal data": "ข้อมูลส่วนบุคคล",
  "Your e-mail": "อีเมลของคุณ",
  "Current Password": "รหัสผ่านปัจจุบัน",
  "You are not authorized, please log in.": "คุณไม่ได้รับอนุญาต กรุณาเข้าสู่ระบบ",
  "Go To Product": "Go To Product",
  "Change to list view": "Change to list view",
  "Change to grid view": "Change to grid view",
  Returns: "Returns",
  "My orders": "คำสั่งซื้ิอของฉัน",
  "Add the address": "Add the address",
  "Set as default shipping": "Set as default shipping",
  "Set as default billing": "Set as default billing",
  "House/Apartment number": "House/Apartment number",
  "Street Name": "Street Name",
  City: "City",
  "State/Province": "State/Province",
  "Zip-code": "Zip-code",
  Country: "Country",
  "Phone number": "Phone number",
  "Please select a country first": "Please select a country first",
  "This field is required": "This field is required",
  "The field should have at least 2 characters": "The field should have at least 2 characters",
  "The field should have at least 4 characters": "The field should have at least 4 characters",
  "The field should have at least 8 characters": "The field should have at least 8 characters",
  "New Password": "New Password",
  "New Products": "New Products",
  "There are no shipping methods available for this country. We are sorry, please try with different country.":
    "There are no shipping methods available for this country. We are sorry, please try with different country.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.":
    "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.":
    "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.",
  "We can't find products matching the selection.": "We can't find products matching the selection.",
  "Page not found": "Page not found",
  "Back to Home page": "Back to Home page",
  "An error occurred": "An error occurred",
  AllProductsFromCategory: "{categoryName} All",
  "Show more": "แสดงมากขึ้น",
  "Show less": "แสดงน้อยลง",
  Yes: "ใช่",
  No: "ไม่",
  "Apply filters": "ใช้ตัวกรอง",
  "The coupon code isn't valid. Verify the code and try again.": "รหัสคูปองไม่ถูกต้อง ยืนยันรหัสแล้วลองอีกครั้ง",
  From: "จาก",
  To: "ถึง",
  "Your customization": "Your customization",
  "Passwords don't match": "Passwords don't match",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":
    "รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษรและต้องมีอย่างน้อย: 1 ตัวพิมพ์ใหญ่หรือตัวพิมพ์เล็ก, 1 ตัวเลขหรืออักขระพิเศษหนึ่งตัว (เช่น , . _ & ? ฯลฯ)",
  "Show all products": "Show all products",
  "Select previously saved address": "เลือกที่อยู่จัดส่ง",
  "Enter different address": "ป้อนที่อยู่อื่น",
  "You must confirm your account. Please check your email for the confirmation link.":
    "You must confirm your account. Please check your email for the confirmation link.",
  "Change Store": "Change Store",
  "Choose Currency": "Choose Currency",
  "Add a review": "เพิ่มรีวิว",
  "Add to cart": "หยิบใส่ตะกร้า",
  Title: "Title",
  Name: "ชื่อ",
  Review: "รีวิว",
  "Add review": "เพิ่มรีวิว",
  "Are you sure you would like to remove this item from the shopping cart?":
    "คุณแน่ใจหรือไม่ว่าต้องการลบรายการนี้ออกจากตะกร้าสินค้า?",
  "Your cart is empty": "รถเข็นของคุณว่างเปล่า",
  "Are you sure?": "คุณแน่ใจไหม?",
  "{0} has been successfully removed from your cart": "{0} ถูกลบออกจากรถเข็นของคุณเรียบร้อยแล้ว",
  Amount: "ยอดเงิน",
  "Thank you for your order!": "ขอขอบคุณสำหรับการสั่งซื้อของคุณ!",
  "Your Purchase": "การซื้อของคุณ",
  "Primary contacts for any questions": "Primary contacts for any questions",
  "Your Account": "บัญชีของคุณ",
  "What can we improve": "What can we improve",
  "Payment date": "Payment date",
  "The user password was changed successfully updated!": "อัปเดตรหัสผ่านผู้ใช้สำเร็จแล้ว!",
  "The user account data was successfully updated!": "ข้อมูลบัญชีผู้ใช้ได้รับการอัปเดตเรียบร้อยแล้ว!",
  "You submitted your review for moderation.": "คุณส่งคำวิจารณ์ของคุณเพื่อการพิจารณา",
  "Starting at": "Starting at",
  "Easy to shop for IT products 24 hours a day.": "ช้อปสินค้าไอทีได้ง่ายตลอด 24 ชั่วโมง",
  "Search Keyword or Item...": "ค้นหาสินค้าหรือรายการอื่นๆ...",
  "My Profile": "ข้อมูลของฉัน",
  "Manage My Account": "จัดการบัญชีของฉัน",
  "Account Overview": "บัญชีของฉัน",
  "My Address": "ที่อยู่ของฉัน",
  "My Order": "คำสั่งซื้อของฉัน",
  "Order History": "ประวัติการสั่งซื้อ",
  "Order history": "ประวัติการสั่งซื้อ",
  "Sign out": "ออกจากระบบ",
  "My Shipping Address": "ที่อยู่จัดส่งสินค้าของฉัน",
  "Edit address": "แก้ไขที่อยู่",
  Landmark: "จุดสังเกต",
  "Tax/VAT Number": "เลขประจำตัวผู้เสียภาษี/เลขประจำตัวประชาชน",
  "Name:": "ชื่อ:",
  "Receiver name:": "ชื่อผู้รับ:",
  "Phone no.:": "เบอร์โทรศัพท์:",
  "Delivery address:": "ที่อยู่จัดส่งสินค้า:",
  "Tax no.:": "เลขประจำตัวผู้เสียภาษี:",
  "Delivery method:": "วิธีการจัดส่ง",
  "View order detail": "ดูรายละเอียดรายการสั่งซื้อ",
  "Total order": "ยอดสั่งซื้อ",
  "%1 item(s) in this package": "%1 รายการในรายการสั่งซื้อนี้",
  "Order Date:": "วันที่สั่งซื้อ:",
  "Related Posts": "กระทู้ที่เกี่ยวข้อง",
  "Enter coupon code": "ใส่รหัสคูปอง",
  "You added coupon to your collection.": "คุณได้เก็บคูปองลงในคอลเลกชันของคุณแล้ว",
  "You must login or register to add coupons to your collection.": "คุณต้องเข้าสู่ระบบหรือลงทะเบียนเพื่อเพิ่มคูปองในคอลเลกชันของคุณ",
  "Add Shipping information": "เพิ่มข้อมูลการจัดส่ง",
  "Ex. Please leave the parcel at reception....": "ตัวอย่าง. กรุณาฝากพัสดุไว้ที่แผนกต้อนรับ....",
  "Do you buy this product for business use?": "คุณซื้อผลิตภัณฑ์นี้เพื่อใช้ในธุรกิจหรือไม่?",
  "Request full tax invoice (Address in tax invoice is the same as billing address)":
    "ขอใบกำกับภาษีแบบเต็ม (ที่อยู่ในใบกำกับภาษีเหมือนกับที่อยู่ในใบกำกับภาษี)",
  "Continue to payment": "ชำระเงินต่อ",
  "Billing address/ full tax invoice address": "ที่อยู่สำหรับการเรียกเก็บเงิน/ที่อยู่ใบกำกับภาษีแบบเต็ม",
  "Billing address is same as shipping address": "ที่อยู่สำหรับเรียกเก็บเงินเหมือนกับที่อยู่ในการจัดส่ง",
  "Address Type:": "ประเภทที่อยู่",
  "Select/ + New Address": "เลือก/เพิ่มที่อยู่ใหม่",
  "Use selected address": "ใช้ที่อยู่ที่เลือก",
  "Order list": "รายการสั่งซื้อ",
  "Back to shopping cart": "กลับไปที่รถเข็นสินค้า",
  "Cart's detail": "รายละเอียดของรถเข็นสินค้า",
  "Review cart": "ตรวจสอบรถเข็นสินค้า",
  "Cart (%1)": "รถเข็น (%1)",
  "(Optional)": "(ไม่จำเป็น)",
  "Find your nearest store": "ค้นหาร้านใกล้คุณ",
  "Sign in": "ลงชื่อเข้าใช้",
  Cart: "รถเข็น",
  "See More": "ดูทั้งหมด",
  Brand: "แบรนด์",
  Search: "ค้นหา",
  "Full-Payment Credit - Debit": "จ่ายเต็มจำนวนด้วย บัตรเครดิต - บัตรเดบิต (ATM)",
  Installment: "ช่องทางการผ่อน",
  Bill: "ชำระเงินผ่าน บิลอื่นๆ",
  Promptpay: "บริการชำระเงินพร้อมเพย์",
  Truewallet: "ทรูวอลเล็ท (True Wallet)",
  Ibanking: "อินเทอร์เน็ต แบงค์กิ้ง",
  "Help & FAQs": "ความช่วยเหลือ & คำถามที่พบบ่อย",
  Download: "ดาวน์โหลด",
  "Find out more": "หาข้อมูลเพิ่มเติม",
  Account: "บัญชี",
  "Vue Storefront Next": "Vue Storefront Next",
  "Search for items": "ค้นหารายการ",
  "Colorful summer dresses are already in store": "ชุดฤดูร้อนสีสันสดใสมีจำหน่ายแล้ว",
  "Learn more": "เรียนรู้เพิ่มเติม",
  Dresses: "เดรส",
  "Cocktail & Party": "ค็อกเทลและปาร์ตี้",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.":
    "ค้นหาชุดค็อกเทลและชุดปาร์ตี้ของผู้หญิงที่น่าทึ่ง โดดเด่นด้วยเดรสลูกไม้และค็อกเทลเมทัลลิกจากแบรนด์โปรดทั้งหมดของคุณ",
  "Subscribe to Newsletters": "สมัครรับจดหมายข่าว",
  "See more": "ดูเพิ่มเติม",
  "SUMMER COLLECTION {year}": "คอลเลคชั่นฤดูร้อน {year}",
  "Shop now": "ช้อปเลย",
  "Linen Dresses": "เดรสผ้าลินิน",
  "T-Shirts": "เสื้อยืด",
  "The Office Life": "ชีวิตในสำนักงาน",
  "Summer Sandals": "รองเท้าแตะฤดูร้อน",
  "Eco Sandals": "รองเท้าแตะอีโค",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "ระวังการขายและกิจกรรมที่จะเกิดขึ้น รับของขวัญและข้อเสนอพิเศษ!",
  Subscribe: "ติดตาม",
  "New Products": "สินค้าใหม่",
  Home: "Home",
  Menu: "เมนู",
  "Linen Dresses": "เดรสผ้าลินิน",
  "Fashion to take away": "แฟชั่นที่จะพาไป",
  "Download our application to your mobile": "ดาวน์โหลดใบสมัครของเราไปยังมือถือของคุณ",
  "Share Your Look": "แบ่งปันรูปลักษณ์ของคุณ",
  "about & services": "เกี่ยวกับ & บริการ",
  "Forgotten password?": "ลืมรหัสผ่าน?",
  "No account": "ไม่มีบัญชี",
  "Register today": "ลงทะเบียนวันนี้",
  Login: "เข้าสู่ระบบ",
  "Your email": "อีเมลของคุณ",
  Password: "รหัสผ่าน",
  "This field is required": "ต้องระบุข้อมูลในช่องนี้",
  Register: "ลงทะเบียน",
  or: "หรือ",
  "login in to your account": "เข้าสู่ระบบบัญชีของคุณ",
  "Sign Up for Newsletter": "สมัครรับจดหมายข่าว",
  "Create an account": "สร้างบัญชี",
  "First Name": "ชื่อ",
  "Last Name": "นามสกุล",
  "I want to create an account": "ฉันต้องการสร้างบัญชี",
  "Forgot Password": "ลืมรหัสผ่าน",
  "Reset Password": "รีเซ็ตรหัสผ่าน",
  "Forgot Password Modal Email": "กรอกอีเมล์ของคุณ",
  "Invalid email": "อีเมลไม่ถูกต้อง",
  Error: "ผิดพลาด",
  "See All": "ดูทั้งหมด",
  "See Blog All": "ดูบทความทั้งหมด",
  "See All Brand": "ดูแบรนด์ทั้งหมด",
  "Read More": "อ่านต่อ",
  Qty: "จำนวน",
  "Buy Now": "ซื้อสินค้า",
  SKU: "SKU",
  "Search results for : ": "ผลการค้นหาสำหรับ : ",
  "Search results for ": "ผลการค้นหา ",
  "Continue Shopping": "ช้อปปิ้งต่อ",
  "Read details": "อ่านเพิ่มเติม",
  "Share : ": "แชร์ : ",
  "More interested products": "สินค้าที่คุณสนใจเพิ่มเติม",
  "Search the blog": "ค้นหาบทความ",
  By: "โดย",
  Views: "เยี่ยมชม",
  "Post not found": "ยังไม่มีบทความ",
  Blog: "ข่าวสาร บทความ",
  Home: "หน้าหลัก",
  "State/Province": "จังหวัด",
  City: "เขต/อำเภอ",
  "Sub District": "แขวง/ตำบล",
  "Zip-code": "รหัสไปรษณีย์",
  Country: "ประเทศ",
  "Phone number": "เบอร์โทรศัพท์",
  "Street Name": "ที่อยู่",
  "House/Apartment number": "ที่อยู่ (เพิ่มเติม)",
  "Please select state/province": "กรุณาเลือกจังหวัด",
  "Please select city": "กรุณาเลือกเขต / อำเภอ",
  "Please select sub district": "กรุณาเลือกแขวง / ตำบล",
  "Update the address": "แก้ไขที่อยู่",
  "Set as default shipping": "ตั้งเป็นที่อยู่จัดส่งเริ่มต้น",
  "Set as default billing": "ตั้งเป็นที่อยู่ออกใบเสร็จเริ่มต้น",
  "The product's required option(s) weren't entered. Make sure the options are entered and try again.": "คุณจำเป็นต้องเลือกตัวเลือก ของสินค้า",
  "Error: The product's required option(s) weren't entered. Make sure the options are entered and try again.": "คุณจำเป็นต้องเลือกตัวเลือก ของสินค้า",
  "Cannot read properties of undefined (reading 'addConfigurableProductsToCart')": "คุณจำเป็นต้องเลือกตัวเลือก ของสินค้า",
  "TypeError: Cannot read properties of undefined (reading 'addConfigurableProductsToCart')": "คุณจำเป็นต้องเลือกตัวเลือก ของสินค้า",
  "Sign in with": "เข้าสู่ระบบด้วย",
  "Sign up with": "สมัครสมาชิกด้วย",
  "Fail! Something went wrong.": "ผิดพลาด! มีบางสิ่งไม่ถูกต้อง กรุณาตรวจสอบ",
  "Welcome to UTECH online Store": "Welcome to UTECH online Store",
  "Set a New Password": "ตั้งรหัสผ่านใหม่",
  "Tax Invoice": "ใบกำกับภาษี",
  "Type of taxpayer": "ประเภทผู้เสียภาษีอากร",
  Individual: "บุคคลธรรมดา",
  Company: "บริษัท",
  "Taxpayer Identification Number": "เลขประจำตัวผู้เสียภาษีอากร",
  "Branch Number": "เลขที่สาขา",
  "Branch Name": "ชื่อสาขา",
  "Company Name": "ชื่อบริษัท",
  "Tax Invoice address": "ที่อยู่ใบกำกับภาษี",
  "My tax invoice and shipping address are the same": "ที่อยู่สำหรับใบกำกับภาษีและการจัดส่งเหมือนกัน",
  "Need tax invoice": "ต้องการใบกำกับภาษี",
  "Save Tax Invoice Address": "บันทึกที่อยู่ใบกำกับภาษี",
  "Choose your free gift": "เลือกของแถมของคุณ",
  left: "ชิ้น",
  "Thank you for your order!": "ขอบคุณสำหรับการสั่งซื้อของคุณ!",
  "We will email you To confirm your order and provide shipping tracking details.":
    "เราจะอีเมลถึงคุณ เพื่อยืนยันการสั่งซื้อและให้รายละเอียดการติดตามการจัดส่งสินค้า",
  "Continue Shopping": "ช้อปปิ้งต่อ",
  "Create Account": "สร้างบัญชี",
  "You can track your order status by creating an account.": "คุณสามารถติดตามสถานะการสั่งซื้อ โดยการสร้างบัญชี",
  "Email Address": "อีเมล",
  "Order #:": "คำสั่งซื้อ #:",
  "You added {0} to your shopping cart.": "คุณเพิ่มสินค้า {0} ลงในรถเข็นสำเร็จ",
  "You updated qty {0} your shopping cart.": "คุณแก้ไขสินค้าจำนวน {0} ในรถเข็นสำเร็จ",
  "apply your coupon successfully": "ใช้คูปองของคุณสำเร็จ",
  "cancel your coupon successfully": "ยกเลิกคูปองของคุณสำเร็จ",
  "Payment Method": "วิธีการชำระเงิน",
  "Tracking Status": "เช็คสถานะคำสั่งซื้อ",
  "Payment Method": "วิธีการชำระเงิน",
  "Tracking Status": "เช็คสถานะคำสั่งซื้อ",
  "Order number or phone number": "หมายเลขคำสั่งซื้อหรือเบอร์โทรศัพท์",
  "Enter the number to check the status of the product": "กรอกหมายเลขเพื่อเช็คสถานะคำสั่งซื้อ",
  "Enter your 9-digit order number or phone number without hyphens.[Example.000000001,08xxxxxxxx]":
    "กรอกหมายเลขคำสั่งซื้อ 9 หลักหรือเบอร์โทรศัพท์ของคุณโดยไม่ต้องมีขีดคั่น [ตัวอย่าง : 000000001,08xxxxxxxx]",
  Pending: "รอชำระเงิน",
  "Confirm Payment": "ชำระเงินแล้ว",
  Processing: "กำลังจัดเตรียมสินค้า",
  Complete: "กำลังจัดส่งสินค้า",
  Delivered: "จัดส่งสำเร็จ",
  Canceled: "ยกเลิกคำสั่งซื้อ",
  "Flash Sale": "Flash Sale",
  "Will start in": "จะเริ่มใน",
  "Will end in": "สิ้นสุดใน",
  OK: "ตกลง",
  Price: "ราคา",
  "This product has been changed back to original price.": "สินค้านี้มีการเปลี่ยนแปลงกลับไปเป็นราคาเดิม",
  "Flash Sale has ended.": "Flash Sale หมดรอบแล้ว",
  "We apologize, but we couldn't find your order based on the information provided. Please double-check the order details and try again. If you continue to experience issues, feel free to contact our support team for assistance. We're here to help!":
    "ขออภัย เราไม่พบคำสั่งซื้อของคุณตามข้อมูลที่ให้ไว้ โปรดตรวจสอบรายละเอียดการสั่งซื้ออีกครั้งแล้วลองอีกครั้ง หากคุณยังคงประสบปัญหา โปรดติดต่อทีมสนับสนุนของเราเพื่อขอความช่วยเหลือ เราพร้อมให้ความช่วยเหลือ!",
  "You added {0} to your wishlist.": "คุณเพิ่มสินค้า {0} ลงในรายการที่ต้องการ",
  "{0} has been successfully removed from your wishlist": "{0} has been successfully removed from your wishlist",
  "All items has been successfully clear from your wishlist": "All items has been successfully clear from your wishlist",
  "Select shipping method": "เลือกวิธีการขนส่ง",
  "Open search": "Open search",
  "Not yet a member of Utech ?": "ยังไม่เป็นสมาชิกของ UTECH ?",
  "or login via": "หรือ เลือกเข้าสู่ระบบผ่าน",
  "Go to checkout with geust": "ดำเนินการต่อโดยไม่เป็นสมาชิก",
  "E-mail": "อีเมล์",
  coupon_please_login: "กรุณาเข้าสู่ระบบ เพื่อใช้โค้ดส่วนลด",
  "The coupon code isn't valid. Verify the code and try again.": "โค้ดส่วนลดไม่ถูกต้อง ลองใหม่อีกครั้ง",
  "Please enter coupon code": "กรุณาระบุโค้ดส่วนลด",
  "The coupon was not found.": "ไม่พบคูปองที่ค้นหา",
  "Total Amount": "ยอดที่ต้องชำระ",
  "Where would you like us to deliver the product to you ?": "ต้องการให้เราส่งมอบสินค้าให้คุณที่ไหน ?",
  "Please specify the recipient's address details": "เลือกที่อยู่จัดส่งของผู้รับ",
  "Please specify company name (if any)": "ระบุชื่อบริษัท (ถ้ามี)",
  "Address Type": "ประเภทที่อยู่",
  "Please specify address type": "ระบุประเภทที่อยู่",
  "Please specify first name": "ระบุชื่อผู้รับ",
  "Please specify last name": "ระบุนามสกุลผู้รับ",
  "Building number or name": "หมายเลขหรือชื่ออาคาร",
  "Please specify building number or name": "ระบุข้อมูล บ้านเลขที่, ชื่ออาคาร",
  "Address detail": "รายละเอียดที่อยู่",
  "Please specify details of road, alley, village": "ระบุรายละเอียด ถนน, ซอย, หมู่",
  "We'll send you a receipt and shipping updates via the email address provided. which cannot be changed This email information can be obtained later. Please check the information is correct.":
    "เราจะส่งใบเสร็จและอัพเดทข้อมูลการจัดส่งให้คุณผ่านอีเมลที่ระบุไว้ ซึ่งไม่สามารถเปลี่ยนแปลงข้อมูลอีเมลนี้ได้ภายหลัง กรุณาตรวจสอบข้อมูลให้ถูกต้อง",
  "Shipping method": "วิธีการจัดส่ง",
  "Are you purchasing this product for business use?": "คุณซื้อผลิตภัณฆ์นี้เพื่อใช้ในธุรกิจหรือไม่ ?",
  "Please specify email": "ระบุอีเมล",
  "Please specify phone number": "ระบุหมายเลขโทรศัพท์",
  "Please specify zip code": "ระบุรหัสไปรษณีย์",
  "Convenient and fast With delivery service within 24 hours": "สะดวกและรวดเร็ว ด้วยบริการจัดส่งภายใน 24 ชม.",
  "We will be ready to ship your order within 24 hours on the next business day. The order is completed":
    "เราพร้อมจัดส่งสินค้าให้คุณภายใน 24 ชั่วโมงในวันทำการหลังคำสั่งซื้อเสร็จสิ้น",
  "Shipping details": "รายละเอียดการจัดส่ง",
  Yes: "ใช่",
  No: "ไม่",
  "Use a different address": "ใช้ที่อยู่อื่น",
  "Default address": "ที่อยู่เริ่มต้น",
  "There are no shipping methods available for this address. We are sorry, please try with a different address":
    "ไม่มีวิธีจัดส่งสำหรับที่อยู่นี้ ขออภัย โปรดลองใช้ที่อยู่อื่น",
  "Choose Payment Method": "เลือกวิธีการชำระเงิน",
  "We value the security of your personal information.": "เราให้ความสำคัญกับความปลอดภัยของข้อมูลส่วนบุคคล",
  "We take personal information very seriously. To ensure the safety of your information.":
    "เราให้ความสำคัญกับเรื่องข้อมูลส่วนบุคคลเป็นอย่างยิ่ง เพื่อความปลอดภัยของข้อมูลของคุณ",
  "We use PaySolutions as a payment gateway.": "เราใช้ช่องทางการชำระเงินผ่าน PaySolutions",
  "Security of your financial information": "ความปลอกภัยของข้อมูลการเงินของคุณ",
  "We take care of the security of your financial information. after payment money finished We will send a receipt to your email immediately.":
    "เราดูแลความปลอดภัยของข้อมูลการเงินของคุณ หลังจากการจ่ายเงินเสร็จสิ้น เราจะส่งใบเสร็จไปทางอีเมลของคุณทันที",
  "Terms of use": "ข้อกำหนดการใช้งาน",
  "Make an order": "ดำเนินการต่อ",
  "To ensure the safety of your financial information We choose to use PaySolutions for UTECH payments.":
    "เพื่อความปลอดภัยสำหรับข้อมูลทางการเงินของคุณ เราเลือกใช้การชำระผ่าน PaySolutions สำหรับ UTECH",
  "What payment method are you comfortable with ?": "คุณสะดวกชำระเงินแบบใด ?",
  "Choose your preferred payment channel and method": "เลือกช่องทางและวิธีการชำระเงินที่คุณต้องการ",
  "Shipping address": "ที่อยู่สำหรับจัดส่ง",
  "Check the order": "ตรวจสอบคำสั่งซื้อ",
  "Payment method": "วิธีการชำระเงิน",
  "Product list": "รายการสินค้า",
  "Confirm order": "ยืนยันคำสั่งซื้อ",
  "Save address book": "บันทึกที่อยู่",
  "Free Gifts": "สินค้าแถม",
  "All SKUs": "ทั้งหมด",
  "One of SKUs": "ชิ้นใดชิ้นหนึ่ง",
  "Help & Additional information": "ช่วยเหลือ & ข้อมูลเพิ่มเติม",
  ข่าวสาร: "ข่าวสาร",
  รีวิวเทคโนโลยี: "รีวิวเทคโนโลยี",
  สาระน่ารู้: "สาระน่ารู้",
  ไลฟ์สไตล์: "ไลฟ์สไตล์",
  ล่าสุด: "ล่าสุด",
  "Contact Us": "ติดต่อเรา",
  "Please fill in complete address information. to calculate shipping rate": "กรุณากรอกข้อมูลที่อยู่ให้ครบถ้วน เพื่อคำนวนค่าขนส่ง",
  "Use previously saved addresses": "ใช้ที่อยู่ที่เคยบันทึกไว้",
  "Select previously saved tax address": "เลือกที่อยู่ใบกำกับภาษี",
  "Taxpayer Type": "ประเภทผู้เสียภาษีอากร",
  "Taxpayer identification number": "เลขประจำตัวผู้เสียภาษีอากร",
  "Company Name": "ชื่อบริษัท",
  "Branch Name": "ชื่อสาขา",
  "Branch Number": "เลขที่สาขา",
  "View All Product": "ดูสินค้าทั้งหมด",
  individual: "บุคคล",
  company: "บริษัท",
  view_all_product: "ดูสินค้า{cat_name}ทั้งหมด",
  "All product in": "สินค้าทั้งหมดใน",
  "Login/Register": "เข้าสู่ระบบ/สมัครสมาชิก",
  "Filter Products": "กรองสินค้า",
  "Clear all filter": "ลบการกรองสินค้าที่เลือก",
  "All products": "สินค้าทั้งหมด",
  Support: "ฝ่ายสนับสนุน",
  "Filter Product": "กรองข้อมูลสินค้า",
  "Filter Products": "กรองข้อมูลสินค้า",
  "Remove filtering of selected products": "ลบการกรองสินค้าที่เลือก",
  "Use to filter the selected products": "ใช้กรองสินค้าที่เลือก",
  "Choose Language": "เลือกภาษา",
  "Special discount for now only": "ลดราคาพิเศษเฉพาะตอนนี้เท่านั้น",
  instock_left: "เหลือสินค้า {item} ชิ้น",
  Today: "วันนี้",
  Tomorrow: "พรุ่งนี้",
  "ends in": "สิ้นสุดใน",
  Expires: "หมดอายุ",
  Used: "ถูกใช้แล้ว",
  "Can be used from": "ใช้ได้ตั้งแต่",
  "Note: We reserve the right to change conditions without prior notice.":"หมายเหตุ : ขอสงวนสิทธิ์การเปลี่ยนแปลงเงื่อนไขโดยไม่ต้องให้ทราบล่วงหน้า",
  "We can't find products matching the selection.":"เราไม่พบสินค้าที่ตรงกับการเลือก",
  "Home":"หน้าแรก",
  "about UTECH":"เกี่ยวกับ UTECH",
  "Products":"สินค้า",
  "News and Blog":"ข่าวสารและบทความ",
  "About Us":"เกี่ยวกับเรา",
  "Privacy Policy":"นโยบายความเป็นส่วนตัว",
  "Product warranty policy":"นโยบายการรับประกันสินค้า",
  "Terms of use":"ข้อกำหนดการใช้งาน",
  "Shop Nearest":"สาขาที่ใกล้คุณ",
  "Menu":"เมนู",
  "My coupon":"คูปองของฉัน",
  "My Coupon":"คูปองของฉัน",
  "All coupons":"คูปองทั้งหมด",
  "Used coupons":"คูปองที่ถูกใช้แล้ว",
  "Expires coupons":"คูปองหมดอายุ",
  "You haven't collected your coupon yet":"คุณยังไม่ได้ทำการเก็บคูปอง",
  "Collect coupons":"เก็บคูปองอื่น ๆ",
  limit_discount: "ส่วนลดจำนวนจำกัด {usage_per_customer} สิทธิ์/การสั่งซื้อ/อีเมล",
  "Only when shopping for participating products through ut.in.th":"เฉพาะช้อปสิ้นค้าที่ร่วมรายการผ่าน ut.in.th เท่านั้น",
  "Use discount code":"กดใช้โค้ดส่วนลด",
  "Change discount code":"กดเปลี่ยนโค้ดส่วนลด",
  "Promotion":"โปรโมชั่น",
  "Not Available":"ไม่สามารถใช้ได้",
  "and":"และ",
  "Coupon cannot be used":"คูปองไม่สามารถใช้งานได้"
};

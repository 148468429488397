var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SfHeader',{staticClass:"sf-header--has-mobile-search",class:{ 'header-on-top': _vm.isSearchOpen },scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"menuToggle desktop-only",class:{
          active: _vm.menuToggle,
        }},[(_vm.isLoaded)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.menuToggle),expression:"menuToggle"}],staticClass:"chk-open-nav",attrs:{"type":"checkbox","aria-label":"chk nav open"},domProps:{"checked":Array.isArray(_vm.menuToggle)?_vm._i(_vm.menuToggle,null)>-1:(_vm.menuToggle)},on:{"click":function($event){return _vm.handleMenuToggle($event)},"change":function($event){var $$a=_vm.menuToggle,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.menuToggle=$$a.concat([$$v]))}else{$$i>-1&&(_vm.menuToggle=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.menuToggle=$$c}}}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"nav-row-header-btn"}),_vm._v(" "),_c('span',{staticClass:"nav-row-header-btn"}),_vm._v(" "),_c('span',{staticClass:"nav-row-header-btn"}),_vm._v(" "),_c('nav',{staticClass:"sf-header__navigation"},[_c('HeaderNavigation',{attrs:{"category-tree":_vm.categoryTree},on:{"clickToCategoryPage":function($event){return _vm.handleClickToCategoryPage($event)}}})],1)]),_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutsideMobile),expression:"onClickOutsideMobile"}],staticClass:"menuToggle smartphone-only",class:{
          active: _vm.menuToggleMobile,
        }},[(_vm.isLoaded)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.menuToggleMobile),expression:"menuToggleMobile"}],staticClass:"chk-open-nav",attrs:{"type":"checkbox","aria-label":"chk nav open"},domProps:{"checked":Array.isArray(_vm.menuToggleMobile)?_vm._i(_vm.menuToggleMobile,null)>-1:(_vm.menuToggleMobile)},on:{"click":function($event){return _vm.loadCategoryMenu($event)},"change":function($event){var $$a=_vm.menuToggleMobile,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.menuToggleMobile=$$a.concat([$$v]))}else{$$i>-1&&(_vm.menuToggleMobile=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.menuToggleMobile=$$c}}}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"nav-row-header-btn"}),_vm._v(" "),_c('span',{staticClass:"nav-row-header-btn"}),_vm._v(" "),_c('span',{staticClass:"nav-row-header-btn"}),_vm._v(" "),(_vm.isLoaded)?_c('MobileCategorySidebar',{attrs:{"category-tree":_vm.categoryTree,"stores":_vm.stores},on:{"close":_vm.closeMenuMobile}}):_vm._e()],1),_vm._v(" "),_c('HeaderLogo')]},proxy:true},{key:"header-icons",fn:function(ref){
        var activeIcon = ref.activeIcon;
return [_c('div',{staticClass:"sf-header__icons"},[_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-account'),expression:"'app-header-account'"}],staticClass:"sf-button--pure sf-header__action search-btn smartphone-only",attrs:{"aria-label":_vm.$t('Search')},on:{"click":_vm.showSearchInput}},[_c('IconSearch',{staticClass:"sf-header__icon",attrs:{"width":"1.25rem","height":"1.25rem"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("Search")))])],1),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-account'),expression:"'app-header-account'"}],staticClass:"sf-button--pure sf-header__action desktop-only",attrs:{"aria-label":_vm.$t('Tracking Status')},on:{"click":function($event){_vm.$router.push(("" + (_vm.localePath('/tracking'))))}}},[_c('span',[_vm._v(_vm._s(_vm.$t("Tracking Status")))])]),_vm._v(" "),(!_vm.isAuthenticated)?_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-account'),expression:"'app-header-account'"}],staticClass:"sf-button--pure sf-header__action header_account",attrs:{"data-testid":"accountIcon","aria-label":_vm.$t('Login/Register')},on:{"click":_vm.handleAccountClick}},[_c('IconSignIn',{staticClass:"smartphone-only",class:{
              'sf-header__icon is-active': activeIcon === 'account',
            },attrs:{"width":"1.25rem","height":"1.25rem"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("Login/Register")))])],1):_vm._e(),_vm._v(" "),(_vm.isAuthenticated)?_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-account'),expression:"'app-header-account'"}],staticClass:"sf-button--pure sf-header__action header_account",attrs:{"data-testid":"accountIcon","aria-label":_vm.user ? (_vm.user.firstname + ' ' + _vm.user.lastname) : ''},on:{"click":_vm.handleAccountClick}},[_c('IconSignIn',{staticClass:"smartphone-only",class:{
              'sf-header__icon is-active': activeIcon === 'account',
            },attrs:{"width":"1.25rem","height":"1.25rem"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.user ? (_vm.user.firstname + ' ' + _vm.user.lastname) : ''))])],1):_vm._e(),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-cart'),expression:"'app-header-cart'"}],staticClass:"sf-button--pure sf-header__action header_cart",attrs:{"aria-label":_vm.$t('Cart')},on:{"click":_vm.goToCart}},[_c('IconCart',{staticClass:"sf-header__icon",class:{
              'sf-header__icon is-active': activeIcon === 'cart',
            },attrs:{"width":"20","height":"20"}}),_vm._v(" "),(_vm.cartTotalItems)?_c('SfBadge',{staticClass:"sf-badge--number cart-badge"},[_vm._v("\n            "+_vm._s(_vm.cartTotalItems)+"\n          ")]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("Cart")))])],1),_vm._v(" "),(_vm.hasCurrencyToSelect)?_c('CurrencySelector'):_vm._e(),_vm._v(" "),(_vm.hasStoresToSelect)?_c('StoreSwitcher',{staticClass:"desktop-only"}):_vm._e()],1)]}},{key:"search",fn:function(){return [_c('SearchBar',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutsideShowSearchInput),expression:"onClickOutsideShowSearchInput"}],class:_vm.isShowSearchInput ? 'show' : '',attrs:{"is-search-open":_vm.isSearchOpen,"search-results":_vm.productSearchResults},on:{"set-is-open":function($event){_vm.isSearchOpen = $event},"set-search-results":function($event){_vm.productSearchResults = $event}}})]},proxy:true}])}),_vm._v(" "),_c('SfOverlay',{attrs:{"visible":_vm.isSearchOpen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }